import React from "react"
import { graphql } from "gatsby"
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Menu from '../components/menu'
import PostsGrid from '../components/posts-grid'
import Footer from '../components/footer'
import Comments from '../components/comments'
import Seo from '../components/seo'
import FeaturedImage from '../components/featured-image'
import FormattedText from '../components/formatted-text'
import PostIdentity from '../components/post-identity'
import ChapterNavigation from '../components/chapter-navigation'
import AdSense from 'react-adsense'


import {siteUrl, adClient, adSlotInArticle} from '../utils/config'
import {getRandomFromArray, findNextChapter} from '../utils/utils'

const Chapter = ({ data }) => {
    const story = data.strapiStories
    const chapter = data.strapiChapters
    const allposts = data.allStrapiPosts.edges
    const allStories = data.allStrapiStories.edges
    const postAndStories = allposts.concat(allStories)
    const morePosts = getRandomFromArray(postAndStories, 3)
    const seo = {
      metaTitle: chapter.title,
      metaDescription: "",
      shareImage: chapter.image,
      article: true,
    }
    let allPageViews = 0
    data.allPageViews.nodes.forEach((page)=>{
      allPageViews += page.totalCount
    })
    return (
    <>
    <Seo seo ={seo}/>
    <Container fluid="md">
        <Row>
        <Col>
            <Menu/>
        </Col>
        </Row>
        <Row id ="content-row">
        <Col>
        <Row>
        <FeaturedImage alt={chapter.title || ""} image={chapter.image || ""} post = {true} />
        </Row>
        <FormattedText title= {story.title || ""} chapterTitle={chapter.title || ""}
          content={chapter.content || ""} allowHTML={true} readerMenu={true} pageViews={allPageViews}
          breadcrumbs = {[
            {
              text:story.title,url:`/stories/${story.slug}`
            },
            {
              text:chapter.title,url:`/stories/${story.slug}/${chapter.slug}`
              },
            ]}/>
        <PostIdentity date={chapter.date} author={story.author} category={story.category} tags={story.tags}/>
        <AdSense.Google
          client={adClient}
          slot={adSlotInArticle}
        />
        <ChapterNavigation storySlug = {story.slug} nextChapter = {findNextChapter(story,chapter)} isFinished = {story?.finished}/>
        <Comments url={`${siteUrl}/stories/${story.slug}/${chapter.slug}`} slug={`stories-${story.slug}-${chapter.slug}`} title={chapter.title}/>
        </Col>
        </Row>
        <Row id="more-posts-row">
        <h4>Entradas relacionadas</h4>
        </Row>
        <Row>
        <PostsGrid posts={morePosts} />
        </Row>
        <Footer/>
    </Container>
    </>

            
    )
}

/*

 */

export const query = graphql`
  query ChapterQuery($storySlug: String!, $chapterSlug: String!, $category: String!, $regexSlug: String!){
    strapiStories (slug: {eq: $storySlug}){
        title
        slug
        finished
        author {
            name
            slug
            donation
        }
        category {
            name
            slug
        }
        tags {
            name
            slug
        }
        chapters{
            slug
            title
            number
        }
    }
    allStrapiPosts (filter:{category:{name:{eq: $category}}, slug:{ne: $storySlug} }, sort: {fields: date, order: DESC}){
        edges{
            node{
              id
              title
              slug
              date
              image {
                localFile {
                  publicURL
                  childImageSharp {
                    gatsbyImageData(layout: FULL_WIDTH)
                  }
                }
              }
              author {
                name
                slug
              }
              category {
                name
                slug
              }
              tags {
                name
                slug
              }
            }
        }
      }
    allStrapiStories (filter:{category:{name:{eq: $category}}, slug:{ne: $storySlug} }, sort: {fields: date, order: DESC}){
      edges{
          node{
            id
            title
            slug
            date
            image {
              localFile {
                publicURL
                childImageSharp {
                  gatsbyImageData(layout: FULL_WIDTH)
                }
              }
            }
            author {
              name
              slug
            }
            category {
              name
              slug
            }
            tags {
              name
              slug
            }
          }
      }
    }
    allPageViews(filter: {id: {regex: $regexSlug}}) {
      nodes {
          totalCount
        }
      }
    strapiChapters (slug:{eq: $chapterSlug}){
      slug
      title
      date
      content
      image {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(height: 300)
            }
          }
      }
  }
}
`

export default Chapter